<!--条款注释组件-->
<template>
  <div class="explanatory">
    注册或登录即表示您已阅读并同意<a class="pointerCursor" @click="$router.push('/termsService')">用户协议</a>
  </div>
</template>
<script>
export default {
  name: 'explanatory'
}
</script>

<style scoped lang="less">
.explanatory{
  text-align: center;
  a{
    border-bottom: 1px solid #16a37f;
  }
}
</style>
